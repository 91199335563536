.padded {
    padding-top: 0.5rem!important;
    padding-bottom: 0.5rem!important;
}

.mt-auto {
    margin-top: auto!important;
}
*, ::after, ::before {
    box-sizing: border-box;
}

/* user agent stylesheet */
footer {
   width: 100%;
   bottom: 0;
   position: fixed;
   height: 10vh;
   display: block;
}
