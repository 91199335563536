.badge.rounded-pill {
    margin-left: 15px;
}

small {
    margin-left: 10px;
}

p.description-text {
    margin: 50px;
}

.btn.btn-sm {
    margin-left: 20px;
    margin-right: 20px;
}
